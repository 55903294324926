






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NavigationLink extends Vue {
  @Prop()
  readonly to!: string;

  @Prop()
  readonly external!: boolean;

  handleClicked(e: Event) {
    e.preventDefault();

    if (this.external) {
      window.open(this.to, '_blank');
    } else if (this.$route.path !== this.to) {
      this.$router.push(this.to);
    }
  }
}
