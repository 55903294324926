































































































































import { Component, Vue } from 'vue-property-decorator';
import { Office, Profile, WebsiteLevel } from 'client-website-ts-library/types';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';
import RateMyAgent from '@/components/RateMyAgent.vue';

@Component({
  components: {
    RateMyAgent,
  },
})
export default class Footer extends Vue {
  private office: Office | null = null;

  private profile: Profile | null = null;

  mounted() {
    this.loadOffice();
  }

  loadOffice(): void {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:

        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
          this.profile = profile;
          this.office = profile.Office;
        });

        break;
      case WebsiteLevel.Office:

        API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
          this.office = office;
        });

        break;
      default:
        Logger.Log(LogLevel.Error, 'Failed to load office for website. Website level is invalid.');
        break;
    }
  }

  get facebookLink(): string | undefined {
    return this.profile?.FacebookUrl;
  }

  get twitterLink(): string | undefined {
    return this.profile?.TwitterUrl;
  }

  get linkedInLink(): string | undefined {
    return this.profile?.LinkedInUrl;
  }

  get rateMyAgentLink(): string | undefined {
    return this.profile?.RateMyAgentUrl;
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }
}
