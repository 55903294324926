











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RateMyAgent extends Vue {
  @Prop({ default: '' })
  public readonly rateMyAgentId!: string;

  mounted() {
    let scr: HTMLScriptElement | null = document.getElementById('scrRateMyAgentIngegration') as HTMLScriptElement | null;
    if (scr) scr.remove();

    setTimeout(() => {
      scr = document.createElement('script');
      scr.src = 'https://widgets.ratemyagent.com/original/bundle.js';
      scr.async = true;
      scr.defer = true;
      scr.id = 'scrRateMyAgentIngegration';

      scr.onload = () => {
        console.log('Rate My Agent Integration Loaded');
      };

      document.body.append(scr);
    }, 500);
  }
}
